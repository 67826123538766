<template>
  <div class="Newsinvitepar">
        <div class="top-title">
      <div class="top-text">/权限开通</div>
    </div>
    <!-- 表单 -->
    <div class="forms">
      <el-form ref="form" label-width="100px" enctype="multipart/form-data">
        <div class="Jurisdiction-form">
        </div>
        <div class="Jurisdiction-form">
          <el-form-item label="账号：" >
            <el-input v-model="listpar.a"></el-input>
          </el-form-item>
        </div>
        <div class="Jurisdiction-form">
          <el-form-item label="密码：">
            <el-input  v-model="listpar.b"></el-input>
          </el-form-item>
        </div>
        <div class="Jurisdiction-form">
          <el-form-item label="备注：">
            <el-input  v-model="listpar.c"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <div class="form-btn">
       <el-button
          type="primary"  class="biy-icon" icon="el-icon-check"
          @click="SinviteBtn()">提交</el-button>
      </div>
    </div>

    <div class="jurisdiction-list">
      账号列表
    </div>
        <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStyles" v-loading="loading">

        <el-table-column prop="login_name" label="账号"> </el-table-column>
        <el-table-column label="密码">
           <template>
                       <span>---------</span> 
         </template>
        </el-table-column>
        <el-table-column prop="bu_remark" label="备注"> </el-table-column>
        <el-table-column label="操作" width="300" class="table-form-btn">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              class="table-form-btn"
              type="text"
              >修改</el-button>
            <el-button
              @click="deleted(scope.row)"
              class="table-form-btn"
              type="text"
              >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10,20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js";
import config from "@/request/config.js"


export default {
  name: "newsinvite",
  components: { },
  data() {
    return {
      listpar: {},
            // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      loading: this.$common.loading,
      list:[],
      edeleted:''
    };
  },
  created() {
   this.getSong()
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
      let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog:'AAAEntity',
        handleMode:'fetchallServicerRoleUser',
        })
    
        if(this.$common.verifyAjaxResult(res,false)==false) return;
            this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },

        //弹窗
      SinviteBtn(){
          this.$confirm('是否确认操作', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.SinviteBtns()
          }).catch(action => {
            console.log("取消");
          })
      },

    //提交接口
      async SinviteBtns(){
      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'AAAEntity',
        handleMode:'upsertServicerRoleUser',
        bizData:{
            id:"",//id为空则为新增角色账号 非空则更新角色账号信息
            loginName:this.listpar.a,//登录号
            loginPwd:this.listpar.b,//新登录密码 为空则不修改
            remark:this.listpar.c,//备注    
        }
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('设置成功', '消息提示', {confirmButtonText: '确定'});
         this.getSong()
      },
      
        //删除弹窗
      deleted(row){
          this.edeleted = row
          this.$confirm('是否确认删除', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.deleteds()
          }).catch(action => {
            console.log("取消");
          })
      },

    //提交接口
      async deleteds(){
      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'AAAEntity',
        handleMode:'removeServicerRoleUser',
        userId:this.edeleted.buid,
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.$alert('删除成功', '消息提示', {confirmButtonText: '确定'});
         this.getSong()
      },


    // 跳转详情页
    handleClick(row) {
      this.$router.push({ path: `/home/Subuserspar/${row.buid}` });
    },


    // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
};
</script>

<style  scoped>

/* 搜索栏样式 */
.top-title {
  width: 94%;
  margin: 0 auto;
  height: 80px;
}

.forms {
  width: 800px;
  margin: 0 auto;
}
.Jurisdiction-form {
  width: 400px;
  /* margin: 0 auto; */
}
.form-btn {
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 100px;
}
.form-btn .biy-icon {
  width: 250px;
  border-radius: 10px;
  background-color: #2ab6708a;
}
.form-btn .biy-icon:nth-of-type(1) {
  background-color: #01847f;
}

.jurisdiction-list{
  padding-top: 10px;
  padding-left: 50px;
  font-size: 18px;
  border-top: 2px solid rgba(153, 153, 153, 0.404);
}
@import "../../assets/css/liststyle.css";
</style>